export default (flashError, hasCartErrors) => ({
    notificationModalIsOpen: false,

    init() {
        this.checkForNotifications();
    },

    checkForNotifications() {
        if (flashError || hasCartErrors) {
            this.notificationModalIsOpen = true;
        }
    }
});